// Set up Sentry
const ExceptionLimiter = {}; // track recent errors for rate limiting

export function initializeSentry(Sentry){
  if (!window._dsn) {
    return
  }

  Sentry.init({
    dsn: window._dsn,
    release: window._RELEASE_VERSION,
    integrations: [Sentry.browserTracingIntegration()],

    // Send back state with errors. Includes user data.
    // Also limits errors with the same message to once every 5 seconds, and ignores browser
    // extensions
    beforeSend: function(event) {
      if (event.exception && event.exception.values && event.exception.values.length > 0) {
        const val = event.exception.values[0]

        if (val.stacktrace && Array.isArray(val.stacktrace.frames) && val.stacktrace.frames.find((f) => f.filename.includes("@webkit-masked-url"))) {
          return null
        }

        const message = `${val.type} ${val.value}`;
        if (message in ExceptionLimiter) {
            return null;
        }
        ExceptionLimiter[message] = true;
        setTimeout(function() { delete ExceptionLimiter[message] }, 5000);
      }

      // 200kb max payload for sentry errors
      // Limit app state to 150kb to start
      const MAX_SIZE = 1024 * 150;
      if (window.cm && window.cm.store && window.cm.store.getState) {
        const state = window.cm.store.getState();
        let truncatedState = state;

        if (JSON.stringify(state).length > MAX_SIZE) {
          truncatedState = {
              adminUser: state.adminUser,
              customer: state.customer,
              pageViewers: state.pageViewers,
              project: state.project,
              user: state.user,
              userAddresses: state.userAddresses,
            }
        }
        event.extra = Object.assign({}, event.extra || {}, {appState: truncatedState});
      }

      return event;
    },

    // We want to ignore a lot of errors from shoddy browser plugins and third parties. Let's ignore those.
    // SOURCE: https://gist.github.com/impressiver/5092952
    // https://docs.sentry.io/product/accounts/quotas/manage-event-stream-guide/#inboundfilters
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'Can\'t find variable: inf',  // guessing this is a plugin error
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      "null is not an object (evaluating 'elt.parentNode')",  // safari introduced changes which breaks some plugins. We don't have an 'elt' element
      /permission denied to access property/i,  // this is an error pertaining to iframes/cross domain issues. Almost always a plugin
      "Ending process and redirecting to login",  // CM error used for as it sounds
      'Blocked a frame with origin "https://www.custommade.com" from accessing a frame with origin "https://staticxx.facebook.com". The frame being accessed set "document.domain" to "facebook.com", but the frame requesting access did not. Both must set "document.domain" to the same value to allow access.',  // issues with ios chrome autofills & facebook. Does not hamper login
      /Blocked a frame with origin "https:\/\/www\.custommade\.com" from accessing a frame with origin "https:\/\/www\.google\..+". Protocols, domains, and ports must match/,
      'ResizeObserver loop limit',  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'https://s.pinimg.com/ct/lib/main',
      'Apple Sign In error: popup_blocked_by_browser',
      'ReferenceError: IMUID is not defined',
      /UET\.addPluginData/,
      /msDiscoverChatAvailable/,
      "Cannot read properties of undefined (reading 'Embeds')",
      'moat_px is not defined',
      'ReferenceError: apstagLOADED is not defined',
      'ReferenceError: ezDenty is not defined'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /kaspersky-labs/i
    ]
  });

  if (window.cm && window.cm.studiosUser && window.cm.studiosUser.isAuthenticated) {
    Sentry.setUser({ id: window.cm.studiosUser.id, email: window.cm.studiosUser.email, username: `${window.cm.studiosUser.firstName} ${window.cm.studiosUser.lastName}` });
  }

  window.Sentry = Sentry;

  if (window.cmEarlyErr) {
    for (const e of window.cmEarlyErr.q) {
      Sentry.withScope(scope => {
        scope.setExtras({ preSentry: true })
        Sentry.captureException(e)
      })
    }
    window.removeEventListener('error', window.cmEarlyErr.h)
    window.cmEarlyErr.q = []
  }
}
