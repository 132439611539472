/**
 * Important globals which are referenced through the window object.
 *
 * These don't reside in the core app as the core app also looks to 'shared' code which assumes
 * these globals exist.
 */
import * as Sentry from "@sentry/browser"
import jQuery from "jquery"
import * as _ from "underscore"
import fancybox from "fancybox"
import fancyboxThumbs from "fancybox/dist/helpers/js/jquery.fancybox-thumbs.cjs.js"
import {initializeSentry} from 'js/studios/shared/sentry'

initializeSentry(Sentry)

window.jQuery = window.$ = jQuery
window._ = _
fancybox(window.jQuery)
fancyboxThumbs(window.jQuery)
